<template>
  <div class="presentations template-1">
    <header class="page-header container">
      <h1>{{ translations.tcPresentations }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcTooltip"
        :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->
      <data-table :fields="upcoming_fields" :items="upcomingPresentations" :addItems="addUpcomingItem"
        :includeAddButton="iCanSee(secured_controls.add_church_presentation_button)"
        @addUpcomingPresentation="addPresentation" :title="translations.tcUpcomingPresentations" :toggle="true"
        :csvUrl="futureChurchPresentationsByCampAsCSVUrl" :pdfUrl="futureChurchPresentationsByCampAsPDFUrl"
        :propSortBy="sortByUpcoming" :propSortDesc="sortDescUpcoming" :reportName="upcomingReportName"
        :i18n="translations.components"></data-table>
      <data-table :fields="past_fields" :items="pastPresentations" :addItems="addPastItem"
        :includeAddButton="iCanSee(secured_controls.add_church_presentation_button)"
        @addPastPresentation="addPresentation" :title="translations.tcPastPresentations" :toggle="true"
        :searchTerm="`presentationsTerm`" :csvUrl="pastChurchPresentationsByCampAsCSVUrl"
        :pdfUrl="pastChurchPresentationsByCampAsPDFUrl" :propSortBy="sortByPast" :propSortDesc="sortDescPast"
        :reportName="pastReportName" :i18n="translations.components"></data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'presentations',
  mixins: [translationMixin],
  data() {
    return {
      presentationsTerm: 'presentationsTerm',
      translations: {},
      hiddenItems: {
        display: false,
      },
      secured_controls: {
        add_church_presentation_button: '2e18fcc4-b81d-4b54-804e-9d9e8932d810',
      },
      upcoming_fields: [],
      addUpcomingItem: {
        display: false,
        text: 'Add Presentation',
        action: 'addUpcomingPresentation',
      },
      past_fields: [],
      addPastItem: {
        display: false,
        text: 'Add Presentation',
        action: 'addPastPresentation',
      },
      upcomingReportName: 'UpcomingPresentations',
      pastReportName: 'PastChurchPresentations',
      sortByUpcoming: 'presentation_date',
      sortDescUpcoming: false,
      sortByPast: 'presentation_date',
      sortDescPast: true,
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getTranslations(),
        await this.getComponentTranslations('data-table', 'security-banner', 'camp-select'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => {
        this.setLoadingStatus(false)
      }, 2000)
    }
  },
  methods: {
    ...mapActions({
      loadAllPresentationsByCamp: 'churchMinistry/loadAllPresentationsByCamp',
      loadChurchPresentationsReportUrls: 'churchMinistry/loadChurchPresentationsReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setPresentationEditKey: 'churchMinistry/setPresentationEditKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([await this.loadAllPresentationsByCamp(), await this.loadChurchPresentationsReportUrls()])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async addPresentation() {
      await this.setSelectedChurchKey('')
      await this.setPresentationEditKey(null)
      this.$router.push('/programs/cm/churchProfile/add-church-presentation')
    },
    filterByDate(condition) {
      let d = new Date()
      let preFiliteredPresentations =
        !!this.$route.path.split('/')[4] && this.$route.path.split('/')[4] !== 'menu'
          ? this.allPresentationsForDataTable.filter((pfp) => pfp.church_key === this.selectedChurchKey)
          : this.allPresentationsForDataTable
      let comparisonDate =
        d.getFullYear() + '' + ('0' + (d.getMonth() + 1)).slice(-2) + '' + ('0' + d.getDate()).slice(-2)
      return condition === '<'
        ? preFiliteredPresentations.filter((item) => item.sortdate < comparisonDate)
        : preFiliteredPresentations.filter((item) => item.sortdate >= comparisonDate)
    },
    loadTranslations() {
      this.addUpcomingItem.text = this.translations.tcAddPresentation
      this.addUpcomingItem.display = this.iCanSee(this.secured_controls.add_church_presentation_button)
      this.addPastItem.text = this.translations.tcAddPresentation
      this.addPastItem.display = this.iCanSee(this.secured_controls.add_church_presentation_button)
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.upcoming_fields = [
        {
          key: 'presentation_date',
          label: `${this.translations.tcDate}`,
          sortable: true,
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
        },
        {
          key: 'church_name',
          label: `${this.translations.tcName}`,
          sortable: true,
        },
        {
          key: 'servicetime',
          label: `${this.translations.tcTime}`,
          sortable: true,
          formatter: (value, key, item) => {
            return value.slice(-7, -1)
          },
        },
        {
          key: 'speaker',
          label: `${this.translations.tcPresenter}`,
          sortable: true,
        },
        {
          key: 'status',
          label: `${this.translations.tcStatus}`,
          sortable: true,
        },
      ]
      this.past_fields = [
        {
          key: 'presentation_date',
          label: `${this.translations.tcDate}`,
          sortable: true,
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
        },
        {
          key: 'church_name',
          label: `${this.translations.tcName}`,
          sortable: true,
        },
        {
          key: 'offering_amount',
          label: `${this.translations.tcOffering}`,
          sortable: true,
          formatter: (value, key, item) => {
            return '$' + value.toFixed(2)
          },
        },
        {
          key: 'speaker',
          label: `${this.translations.tcPresenter}`,
          sortable: true,
        },
        {
          key: 'status',
          label: `${this.translations.tcStatus}`,
          sortable: true,
        },
      ]
    },
  },
  computed: {
    ...mapGetters({
      all_presentations_by_camp: 'churchMinistry/all_presentations_by_camp',
      futureChurchPresentationsByCampAsCSVUrl: 'churchMinistry/datalist1CSVUrl',
      futureChurchPresentationsByCampAsPDFUrl: 'churchMinistry/datalist1PDFUrl',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      pastChurchPresentationsByCampAsCSVUrl: 'churchMinistry/datalist2CSVUrl',
      pastChurchPresentationsByCampAsPDFUrl: 'churchMinistry/datalist2PDFUrl',
      prefCulture: 'user/userPreferredCulture',
      selectedChurchKey: 'user/userSelectedChurchKey',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path.substr(0, 26))
    },
    allPresentationsForDataTable() {
      return this.all_presentations_by_camp.map((p) => {
        // Translate `status` for each item in the data table
        if (p.status === 'Complete' && this.translations.components) {
          p.status = this.translations.components['data-table'].tcComplete || 'Complete'
        } else if (p.status === 'Incomplete' && this.translations.components) {
          p.status = this.translations.components['data-table'].tcIncomplete || 'Incomplete'
        } else if (p.status === 'Pending' && this.translations.components) {
          p.status = this.translations.components['data-table'].tcPending || 'Pending'
        }
        // DataTable component relies on speaker key property being named ind_key
        return { ...p, ind_key: p.speaker_ind_key }
      })
    },
    upcomingPresentations() {
      if (this.allPresentationsForDataTable.length > 0) {
        return this.filterByDate('>=')
      } else {
        return []
      }
    },
    pastPresentations() {
      if (this.allPresentationsForDataTable.length > 0) {
        return this.filterByDate('<')
      } else {
        return []
      }
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.presentations {
  .g-table {

    td,
    th {
      &:nth-of-type(1) {
        width: 200px;
      }

      &:nth-of-type(2) {
        width: 350px;
      }

      &:nth-of-type(3) {
        width: 125px;
      }

      &:nth-of-type(4) {
        width: 325px;
      }

      &:nth-of-type(5) {
        width: auto;
      }
    }
  }
}
</style>
